




























































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { Location } from 'vue-router/types/router'

@Component
export default class BasketProduct extends Vue {
  /**
   * Тэг отмечающий новый продукт или продукт со скидкой
   */
  @Prop({ type: String, default: null })
  readonly tag!: 'new'| 'discount' | null

  /**
   * Название продукта
   */
  @Prop({ type: String, required: true })
  readonly title!: string

  /**
   * Вес в граммах
   */
  @Prop({ type: Number, default: 999 })
  readonly weight!: number

  /**
   * Килокалорий
   */
  @Prop({ type: Number, default: 999 })
  readonly kcal!: number

  /**
   * Белки
   */
  @Prop({ type: Number, default: 999 })
  readonly proteins!: number

  /**
   * Жиры
   */
  @Prop({ type: Number, default: 999 })
  readonly fats!: number

  /**
   * Углеводы
   */
  @Prop({ type: Number, default: 999 })
  readonly carbohydrates!: number

  /**
   * Отмечает продукт неактивным
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  /**
   * Адрес детальной страницы продукта
   */
  @Prop({ type: [String, Object], required: true })
  readonly productRoute!: string | Location

  /**
   * Скрывает кнопку добавления
   */
  @Prop({ type: Boolean, default: false })
  readonly hideAddBtn!: boolean

  /**
   * Связывает кол-во товара
   */
  @PropSync('quality', { type: Number, default: 0 })
  syncedQuality!: number
}
